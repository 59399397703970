import { Body } from '@sumup/circuit-ui';
import type { FC } from 'react';

import type { OverviewContent } from '../../OrderOverview';
import {
  DescriptionContainer,
  FreeDeliveryText,
} from '../../OrderOverview.styles';

type BusinessAccountRowContent = Pick<
  OverviewContent,
  'businessAccountCostsLabel' | 'businessAccountDescription'
>;

export interface Props extends BusinessAccountRowContent {
  hasBusinessAccount?: boolean;
  usedForCart: boolean;
}

export const BusinessAccountRow: FC<Props> = (props) => {
  const {
    businessAccountCostsLabel,
    businessAccountDescription,
    hasBusinessAccount,
    usedForCart,
  } = props;

  return hasBusinessAccount ? (
    <DescriptionContainer alignItems="start" usedForCart={usedForCart}>
      <Body>{businessAccountDescription}</Body>
      <FreeDeliveryText>{businessAccountCostsLabel}</FreeDeliveryText>
    </DescriptionContainer>
  ) : null;
};
