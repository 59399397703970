import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { getPaymentPlan } from 'shared/infra/storefront/orders';
import logger from 'shared/services/logger';
import type { PaymentPlan } from 'shared/services/PaymentPlanService';
import type { OrderDetails } from 'shared/store/order/types';
import { getChannelLink } from 'shared/utils/channel-link';
import type { Maybe } from 'types/util';

export type UsePaymentPlanResult =
  | { state: 'idle' }
  | { state: 'loading' }
  | { state: 'success'; data: Maybe<PaymentPlan> };

export const usePaymentPlan = (
  orderDetails: OrderDetails,
): UsePaymentPlanResult => {
  const { query, replace } = useRouter();
  const [paymentPlanState, setPaymentPlanState] =
    useState<UsePaymentPlanResult>({
      state: 'idle',
    });

  const { id: orderId, metadata: orderMetadata } = orderDetails;

  useEffect(() => {
    if (!orderId || paymentPlanState.state !== 'idle') {
      return;
    }

    const fetchPaymentPlan = async (): Promise<void> => {
      if (!orderMetadata?.has_payment_plan) {
        setPaymentPlanState({ state: 'success', data: null });
        return;
      }

      setPaymentPlanState({ state: 'loading' });

      try {
        const orderPaymentPlan = await getPaymentPlan(orderId);
        setPaymentPlanState({ state: 'success', data: orderPaymentPlan });
      } catch (err) {
        logger.error(err, 'Could not get payment plan');

        void replace({
          pathname: getChannelLink('/checkout/failure'),
          query,
        });
      }
    };

    void fetchPaymentPlan();
  }, [
    orderId,
    orderMetadata?.has_payment_plan,
    paymentPlanState.state,
    query,
    replace,
  ]);

  return paymentPlanState;
};
