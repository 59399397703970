import type { Product } from 'productSelection/types/products';
import { getPromotionsByCouponCode } from 'shared/infra/commerceLayer/promotions';

export const isValidCoupon = async (
  couponCode: string,
  itemsInBasket: Product[],
): Promise<boolean> => {
  const couponCodePromotions = await getPromotionsByCouponCode([couponCode]);

  const validPromo = Object.entries(couponCodePromotions).find(
    ([skuCode, promotion]) => {
      const item = itemsInBasket.find((i) => i.code === skuCode);

      return item && item?.quantity <= promotion.purchaseLimit;
    },
  );

  return !!validPromo;
};
