import type { NextApiRequestCookies } from 'next/dist/server/api-utils';

import { getExperimentCookieKey, getFlagCookieKey } from './edge-cookies';
import type { ExperimentConfig, FlagConfig } from './flag-config';

export const isFlagEnabledOnServer = (
  flagConfig: FlagConfig,
  request?: { cookies?: NextApiRequestCookies },
): boolean => {
  const cookieKey = getFlagCookieKey(flagConfig.flagKey);
  const cookieVal = request?.cookies && request.cookies[cookieKey];

  return cookieVal === 'true';
};

export const getExperimentVariationOnServer = (
  experimentConfig: ExperimentConfig,
  request?: { cookies?: NextApiRequestCookies },
): string => {
  const cookieKey = getExperimentCookieKey(experimentConfig.experimentKey);
  const cookieVal = request?.cookies && request.cookies[cookieKey];

  return cookieVal || undefined;
};

export const isInExperimentVariationOnServer = (
  experimentConfig: ExperimentConfig,
  variationKey: string,
  request: { cookies?: NextApiRequestCookies },
): boolean => {
  const variation = getExperimentVariationOnServer(experimentConfig, request);

  return variation === variationKey;
};
