import { css } from '@emotion/react';
import styled from '@emotion/styled';
import NextImage from 'next/image';
import type { CSSProperties } from 'react';

export const StyledNextImage = styled(NextImage)<{
  objectFit?: CSSProperties['objectFit'];
}>(
  ({ objectFit }) => css`
    max-width: 100%;
    height: auto;
    ${objectFit && `object-fit: ${objectFit}`}
  `,
);
