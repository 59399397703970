import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { removeLocalOrderInformation } from 'shared/services/OrderInformationService';
import { resetOrder as resetOrderAction } from 'shared/store/order/actions';
import { resetPayment } from 'shared/store/payment/actions';
import { resetPromotions } from 'shared/store/promotions/actions';

export const useResetOrderState = (): (() => void) => {
  const dispatch = useDispatch();

  const resetOrderState = useCallback(() => {
    removeLocalOrderInformation();

    dispatch(resetOrderAction());
    dispatch(resetPromotions());
    dispatch(resetPayment());
  }, [dispatch]);

  return resetOrderState;
};
