import {
  experimentsParser,
  type types as OptimizelyTypes,
} from '@sumup/optimizely-full-stack';
import type { NextRequest } from 'next/server';
import queryString from 'query-string';

export const EXPERIMENT_PREVIEW_PARAM = 'optimizelyExperiment';
export const FLAG_PREVIEW_PARAM = 'optimizelyFlag';

export const getFlagsFromURLQuery = (request: NextRequest): string[] => {
  const queryParams = queryString.parse(request.nextUrl.search);
  const previewFlagKeys = queryParams[FLAG_PREVIEW_PARAM];

  if (!previewFlagKeys) {
    return [];
  }

  return Array.isArray(previewFlagKeys) ? previewFlagKeys : [previewFlagKeys];
};

export const getExperimentsFromURLQuery = (
  request: NextRequest,
): OptimizelyTypes.OptimizelyExperiment[] => {
  const queryParams = queryString.parse(request.nextUrl.search);
  const previewExperimentKeys = queryParams[EXPERIMENT_PREVIEW_PARAM];

  if (!previewExperimentKeys) {
    return [];
  }

  const parseString = Array.isArray(previewExperimentKeys)
    ? previewExperimentKeys.join('&')
    : previewExperimentKeys;

  return experimentsParser.parse(parseString);
};
