import { Body } from '@sumup/circuit-ui';
import type { FC } from 'react';

import * as TestIds from 'shared/constants/TestIds';

import { TotalAmountContainer } from '../../OrderOverview.styles';

export type Props = {
  amount: string;
  paymentStartLabel?: string;
  title: string;
  usedForCart: boolean;
};

export const TotalAmountRow: FC<Props> = (props) => {
  const { amount, paymentStartLabel, title, usedForCart } = props;

  return (
    <>
      <TotalAmountContainer
        data-testid={TestIds.OrderOverview.Total}
        usedForCart={usedForCart}
      >
        <Body variant="highlight">{title}</Body>
        <Body variant="highlight">{amount}</Body>
      </TotalAmountContainer>

      {paymentStartLabel && <Body size="two">{paymentStartLabel}</Body>}
    </>
  );
};
