import { action } from 'typesafe-actions';

import {
  PromotionsTypes,
  type LoadPromotionsAction,
  type LoadPromotionsFailureAction,
  type LoadPromotionsSuccessAction,
  type ResetPromotionsAction,
} from './types';

export const loadPromotions = (
  coupons: string[],
  scope: string,
  locale: string,
): LoadPromotionsAction =>
  action(PromotionsTypes.LOAD_PROMOTIONS_REQUEST, {
    coupons,
    scope,
    locale,
  });

export const loadPromotionsSuccess = (
  promotionsBySku: LoadPromotionsSuccessAction['payload']['details'],
): LoadPromotionsSuccessAction =>
  action(PromotionsTypes.LOAD_PROMOTIONS_SUCCESS, {
    details: promotionsBySku,
  });

export const loadPromotionsFailure = (
  message?: LoadPromotionsFailureAction['payload']['message'],
): LoadPromotionsFailureAction =>
  action(PromotionsTypes.LOAD_PROMOTIONS_FAILURE, { message });

export const resetPromotions = (): ResetPromotionsAction =>
  action(PromotionsTypes.RESET_PROMOTIONS);
