import {
  OptimizelyDecideOption,
  createInstance,
} from '@optimizely/optimizely-sdk';
import type { Client as OptimizelyClient } from '@optimizely/optimizely-sdk/dist/optimizely.lite.min';
import type { NextFetchEvent } from 'next/server';

import { getDatafile } from './data-file';
import { createEventDispatcher } from './edge-event-dispatcher';

export async function getOptimizelyEdgeClient(
  eventDispatcher: NextFetchEvent,
): Promise<OptimizelyClient> {
  const datafile = await getDatafile();

  return createInstance({
    datafile,
    clientEngine: 'javascript-sdk/vercel-edge',
    defaultDecideOptions: [OptimizelyDecideOption.DISABLE_DECISION_EVENT],
    eventDispatcher: createEventDispatcher(eventDispatcher),
  });
}
