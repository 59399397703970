import { useRouter } from 'next/router';
import { useEffect, useState, type FC } from 'react';

import type { Product } from 'productSelection/types/products';
import { usePaymentPlan } from 'shared/hooks/orders/usePaymentPlan';
import { getSubscription } from 'shared/infra/storefront/orders';
import logger from 'shared/services/logger';
import {
  combineProductsInfo,
  hasOrderSubscriptionInCart,
  type Subscription,
} from 'shared/services/ProductService';
import { useTypedSelector } from 'shared/store';
import type { OrderDetails, OrderLineItem } from 'shared/store/order/types';
import { createCFProductId } from 'shared/types/ids';
import { getChannelLink } from 'shared/utils/channel-link';
import { isSumUpOneInProductList } from 'src/cart/services/CartOverviewService';

// External Props
// withOrderInfo use these props to decide injected props
interface ExternalProps {
  productsContentful: Product[];
  largestInstallmentInCart?: number;
}

// Injected Props
interface InjectedOrderInfoProps extends OrderDetails {
  products: Product[];
  outOfCatalogLineItems: OrderLineItem[];
}

type Subtract<T, K> = Pick<T, Exclude<keyof T, keyof K>>;

// https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb injector pattern
// This removes the injected prop from the prop types of the wrapped component,
// which allows us to require the prop (in this case 'products')
// inside the wrapped component, while also not having to provide them
// in the page/component that is importing the wrapped component
// to keep TypeScript from breaking
// see OrderSummary (wrapped comp) used in ThankYou page as example
export function withOrderInfo<T extends Partial<InjectedOrderInfoProps>>(
  Comp: FC<T>,
): FC<Subtract<T, Partial<InjectedOrderInfoProps>> & ExternalProps> {
  const displayName = Comp.displayName || Comp.name || 'Component';

  const WrappedComponent: FC<T & ExternalProps> = (props) => {
    const { largestInstallmentInCart, productsContentful } = props;
    const {
      orderDetails,
      products: productsInCart,
      loading,
    } = useTypedSelector((state) => state.order);
    const { query, replace } = useRouter();

    const [subscriptionDetails, setSubscriptionDetails] =
      useState<Subscription | null>(null);

    const paymentPlanState = usePaymentPlan(orderDetails);

    useEffect(() => {
      if (!orderDetails.id) {
        return;
      }

      const fetchSubscription = async (): Promise<void> => {
        if (!hasOrderSubscriptionInCart(productsInCart, productsContentful)) {
          return;
        }

        try {
          const subscription = await getSubscription(orderDetails.id);
          setSubscriptionDetails(subscription);
        } catch (err) {
          logger.error(err, 'Could not get subscription details');

          void replace({
            pathname: getChannelLink('/checkout/failure'),
            query,
          });
        }
      };

      void fetchSubscription();
    }, [orderDetails.id, productsContentful, productsInCart, query, replace]);

    const { products, outOfCatalogLineItems } = combineProductsInfo(
      productsContentful,
      productsInCart,
      subscriptionDetails,
    );

    // Get installments for current order
    const productSKUs = productsInCart.map((p) => p.code);
    const productInstallments = productsContentful
      .filter((p) => productSKUs.includes(createCFProductId(p.code)))
      .map((p) => p.numberOfInstallments);

    const hasBusinessAccount = !!productsInCart.find(
      (product) =>
        !!products.find((p) => p?.businessAccount?.code === product.code),
    );

    const hasSumUpOne = isSumUpOneInProductList(productsInCart);

    const largestInstallment =
      largestInstallmentInCart || Math.max(...productInstallments);

    const paymentPlan =
      paymentPlanState.state === 'success' ? paymentPlanState.data : null;

    return (
      <Comp
        {...props}
        {...orderDetails}
        products={products}
        outOfCatalogLineItems={outOfCatalogLineItems}
        paymentPlan={paymentPlan} // TODO: pass the entire state? this way we could distinguish between loading state and success with data == null
        loading={loading}
        largestInstallmentInCart={largestInstallment}
        hasBusinessAccount={hasBusinessAccount}
        hasSumUpOne={hasSumUpOne}
      />
    );
  };

  WrappedComponent.displayName = `withOrderInfo_${displayName}`;

  return WrappedComponent;
}
