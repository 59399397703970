import { Button, Input } from '@sumup/circuit-ui';
import type { ChangeEvent, SyntheticEvent } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCoupon } from 'checkout/hooks/useCoupon';
import type { Product } from 'productSelection/types/products';
import * as TestIds from 'shared/constants/TestIds';
import { useContent } from 'shared/context/Content';
import { useGetOrder } from 'shared/hooks/orders/useGetOrder';
import { useResetOrderState } from 'shared/hooks/orders/useResetOrderState';
import {
  shouldResetOrder,
  shouldUnplaceOrder,
  unplaceOrder,
} from 'shared/infra/storefront/orders';
import { useTypedSelector } from 'shared/store';
import { applyCouponFailure } from 'shared/store/order/actions';
import getInputAutoComplete from 'shared/utils/get-input-autocomplete';

import { isValidCoupon } from './CouponFormService';

export type CouponFormContent = {
  notApplicableCouponMessage: string;
  invalidCouponMessage: string;
  couponFieldPlaceholder: string;
  couponFieldLabel: string;
  submitButtonLabel: string;
};

export interface Props {
  products: Product[];
  disabled?: boolean;
}

const CouponForm = (props: Props) => {
  const { addCoupon, resetCoupon } = useCoupon();
  const { couponForm: couponFormContent } = useContent() as {
    couponForm: CouponFormContent;
  };
  const getOrder = useGetOrder();
  const dispatch = useDispatch();
  const resetOrder = useResetOrderState();

  const couponCodeFromState = useTypedSelector((state) => state.order.coupon);

  const orderID = useTypedSelector((state) => state.order.orderDetails.id);

  const [couponCode, setCouponCode] = useState('');

  const autoComplete = getInputAutoComplete();

  const onInputChange = (
    event: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
  ): void => {
    setCouponCode(event.target.value);

    if (!event.target.value) {
      resetCoupon();
    }
  };

  const handleSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault();

    const isValid = await isValidCoupon(couponCode, props.products);
    if (!isValid) {
      dispatch(applyCouponFailure('error applying coupon code'));
      return;
    }

    await addCoupon(couponCode, orderID);

    const order = await getOrder(orderID);

    if (
      shouldResetOrder(order.status, order?.payment_method?.reference ?? null)
    ) {
      resetOrder();

      return;
    }

    if (
      shouldUnplaceOrder(order.status, order?.payment_method?.reference ?? null)
    ) {
      await unplaceOrder(order.id);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '100%',
      }}
      action=""
    >
      <div
        css={{
          width: 'calc(100% - 20px)',
          marginRight: '20px',
        }}
      >
        <Input
          data-testid={TestIds.Payment.CouponField}
          invalid={couponCodeFromState.hasError}
          disabled={props.disabled}
          validationHint={
            couponCodeFromState.hasError &&
            // TODO: we used to have this field, but it has a long text and is not aligned
            // with a design.
            // couponFormContent.invalidCouponMessage
            couponFormContent.notApplicableCouponMessage
          }
          onChange={onInputChange}
          hideLabel={false}
          label={couponFormContent.couponFieldLabel}
          value={couponCode}
          placeholder={couponFormContent.couponFieldPlaceholder}
          autoComplete={autoComplete}
        />
      </div>
      <div>
        <Button disabled={props.disabled}>
          {couponFormContent.submitButtonLabel}
        </Button>
      </div>
    </form>
  );
};

export default CouponForm;
