import type { Maybe } from 'types/util';

import isServer from '../is-server';

type BrowserNameAlias =
  | 'edge'
  | 'ie'
  | 'opera'
  | 'firefox'
  | 'chrome'
  | 'safari';

type PlatformNameAlias = 'android' | 'ios' | 'mac' | 'win' | 'unix' | 'linux';

const PLATFORM_DICT: Record<string, RegExp> = {
  android: /Android/i,
  ios: /iPhone|iPad|iPod/i,
  mac: /Mac/,
  win: /Win/,
  unix: /X11/,
  linux: /Linux/,
};

const BROWSER_DICT: Record<string, RegExp> = {
  edge: /Edge/,
  ie: /(MSIE)|(Trident)/,
  opera: /Opera/,
  firefox: /Firefox/,
  chrome: /Chrome/,
};

function matchDictKeyToString<R extends string>(
  dict: Record<string, RegExp>,
  str: string,
): Maybe<R> {
  return (Object.keys(dict).find((key) => dict[key].test(str)) as R) || null;
}

function detectPlatform(userAgentString: string): Maybe<PlatformNameAlias> {
  return matchDictKeyToString<PlatformNameAlias>(
    PLATFORM_DICT,
    userAgentString,
  );
}

function detectBrowser(
  platform: Maybe<PlatformNameAlias>,
  userAgentString: string,
): BrowserNameAlias | null {
  const browser = matchDictKeyToString<BrowserNameAlias>(
    BROWSER_DICT,
    userAgentString,
  );

  if (platform === 'ios' && !browser) {
    return 'safari';
  }

  return browser;
}

function isRunningInApp(): boolean {
  if (isServer) {
    return false;
  }
  // Detects the Webview based on the in_app query param as per this ticket:
  // https://sumupteam.atlassian.net/browse/DAS-361
  const { search } = window.location;
  return search.indexOf('in_app') > -1 || search.indexOf('in-app') > -1;
}

export interface Result {
  platform: Maybe<PlatformNameAlias>;
  browser: Maybe<BrowserNameAlias>;
  isWebView: boolean;
  isMobile: boolean;
}

export function getUserAgent(): Result {
  // Obtain user agent
  const userAgentString = !isServer ? window.navigator.userAgent : '';

  // Platform components
  const platform = detectPlatform(userAgentString);
  const browser = detectBrowser(platform, userAgentString);
  const isMobile = platform === 'ios' || platform === 'android';
  const isWebView = isRunningInApp();

  return {
    platform,
    browser,
    isWebView,
    isMobile,
  };
}
