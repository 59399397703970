import type { FC } from 'react';

import { useInputState } from 'shared/hooks/helpers/useInputState';

import {
  StyledBinIcon,
  StyledButton,
  StyledMinusIcon,
  StyledPlusIcon,
  StyledQuantity,
  StyledQuantitySelector,
} from './QuantitySelector.styles';

export interface Props {
  quantity: number;
  onChange: (quantity: number) => void;
  quantityLimit?: number;
}

const QuantityRemoveIcon: FC<{ quantity: number }> = ({ quantity }) =>
  quantity === 1 ? <StyledBinIcon size="16" /> : <StyledMinusIcon size="16" />;

const QuantitySelector: FC<Props> = ({
  onChange,
  quantity: initialQuantity,
  quantityLimit,
}) => {
  const [quantity, setQuantity] = useInputState(initialQuantity);

  const isSelectionDisabled = quantity === 0;
  const hasReachedQuantityLimit = quantityLimit && quantity >= quantityLimit;
  const canIncreaseQuantity = !isSelectionDisabled && !hasReachedQuantityLimit;
  const quantityToDisplay =
    String(quantity).length > 1
      ? String(quantity)
      : String(quantity).padStart(2, '0');

  const handleChange = (newQuantity: number): void => {
    setQuantity(newQuantity);
    onChange(newQuantity);
  };

  const removeProduct = (): void => handleChange(quantity - 1);
  const addProduct = (): void => handleChange(quantity + 1);

  return (
    <StyledQuantitySelector>
      <StyledButton
        data-testid="cart-product-quantity--removeBtn"
        aria-label="-"
        removeButton
        disabled={isSelectionDisabled}
        onClick={removeProduct}
      >
        <QuantityRemoveIcon quantity={quantity} />
      </StyledButton>
      <StyledQuantity data-testid="cart-product-quantity">
        {quantityToDisplay}
      </StyledQuantity>
      <StyledButton
        data-testid="cart-product-quantity--addBtn"
        aria-label="+"
        addButton
        disabled={!canIncreaseQuantity}
        onClick={addProduct}
      >
        <StyledPlusIcon size="16" />
      </StyledButton>
    </StyledQuantitySelector>
  );
};

export default QuantitySelector;
