import { getCookie } from 'shared/infra/cookies';

import { getExperimentCookieKey, getFlagCookieKey } from './edge-cookies';
import type { ExperimentConfig, FlagConfig } from './flag-config';

export const isFlagEnabledInBrowser = (flagConfig: FlagConfig): boolean => {
  const cookieKey = getFlagCookieKey(flagConfig.flagKey);
  const cookieVal = getCookie(cookieKey);

  if (!cookieVal) {
    return false;
  }

  return cookieVal === 'true';
};

export const getExperimentVariationInBrowser = (
  experimentConfig: ExperimentConfig,
): string => {
  const cookieKey = getExperimentCookieKey(experimentConfig.experimentKey);
  const cookieVal = getCookie(cookieKey);

  if (!cookieVal) {
    return undefined;
  }

  return cookieVal;
};

export const isInExperimentVariationInBrowser = (
  experimentConfig: ExperimentConfig,
  variationKey: string,
): boolean => {
  const variation = getExperimentVariationInBrowser(experimentConfig);

  return variation === variationKey;
};
