import type { Order } from '@commercelayer/sdk';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import * as clOrderAPI from 'shared/infra/commerceLayer/orders';
import {
  processCouponCodeToOrder,
  translateOrderToStore,
} from 'shared/infra/commerceLayer/utils';
import {
  dispatchInputErrorEvent,
  dispatchVoucherAppliedEvent,
} from 'shared/services/tracker/events';
import {
  applyCoupon,
  applyCouponFailure,
  applyCouponSuccess,
  resetCoupon as resetCouponAction,
} from 'shared/store/order/actions';

type UseCouponResult = {
  addCoupon: (code: string, orderID: string) => Promise<Order | undefined>;
  resetCoupon: () => void;
};

export const useCoupon = (): UseCouponResult => {
  const dispatch = useDispatch();

  const resetCoupon = useCallback((): void => {
    dispatch(resetCouponAction());
  }, [dispatch]);

  const addCoupon = useCallback(
    async (code: string, orderID: string): Promise<Order | undefined> => {
      try {
        // TODO: this dispatch only sets the state to
        // loading. We must revisit our loading strategy.
        dispatch(applyCoupon(code));

        const updatedOrder = await clOrderAPI.addCoupon(
          orderID,
          processCouponCodeToOrder(code),
        );

        // TODO: Also translate the products to the store after
        // coupon update when unified shop experiment is concluded.
        dispatch(applyCouponSuccess(translateOrderToStore(updatedOrder)));
        void dispatchVoucherAppliedEvent({ couponCode: code });

        return updatedOrder;
      } catch (err) {
        dispatch(applyCouponFailure('error applying coupon code'));
        void dispatchInputErrorEvent({ errorField: 'voucher' });
      }

      return undefined;
    },
    [dispatch],
  );

  return { addCoupon, resetCoupon };
};
